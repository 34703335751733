import {
  AuthState,
  OktaAuthOptions,
  OktaAuthCoreInterface,
  OktaAuthOAuthInterface,
} from '@okta/okta-auth-js';
import logEvent, { LogEvent, LogEventStatus } from 'utils/logEvent';
import windowPostMessage from 'utils/windowPostMessage';

const datadogProd = {
  applicationId: 'acf07da9-5252-4195-9e05-146de9d0f631',
  clientToken: 'pub3d2ac3ded2c8c500a7ca1e4f1e2394fe',
};

const datadogNonProd = {
  applicationId: 'a8c3461d-e18a-4539-9b0f-4f4833918058',
  clientToken: 'pub81d618dcf76d9080292c09d3a983fe21',
};

type Config = {
  version?: string;
  baseUrl: string;
  dashboard: string;
  irisApi: string;
  oneIdManagementApi: string;
  oidc: OktaAuthOptions & {
    issuer: string;
    clientId: string;
    scopes: string[];
  };
  datadog: {
    env: string;
    applicationId: string;
    clientToken: string;
  };
};

const baseConfig = {
  version: process.env.REACT_APP_VERSION,
};

const baseOidcConfig: OktaAuthOptions & { scopes: string[] } = {
  redirectUri: `${window.location.origin}/login`,
  postLogoutRedirectUri: `${window.location.origin}/logout`,
  scopes: ['openid', 'oneid', 'email', 'profile'],
  pkce: true,
  devMode: process.env.NODE_ENV === 'development',
  storageManager: {
    token: {
      storageTypes: ['sessionStorage'],
    },
  },
  cookies: {
    secure: true,
  },
  transformAuthState: async (
    oktaAuth: OktaAuthCoreInterface,
    authState: AuthState,
  ): Promise<AuthState> => {
    if (!authState?.isAuthenticated) {
      return authState;
    }
    const sessionExists = await oktaAuth?.session?.exists();
    if (!sessionExists) {
      oktaAuth?.tokenManager?.clear();
    }

    return {
      ...authState,
      isAuthenticated: authState.isAuthenticated && sessionExists,
    };
  },
  restoreOriginalUri: async (oktaAuth: OktaAuthOAuthInterface, originalUri) => {
    let url;
    try {
      url = new URL(originalUri as string);
    } catch {
      url = new URL(window.location.origin);
    }
    const { authType } = JSON.parse(
      sessionStorage.getItem('loginData') || '{}',
    );
    const valid =
      /.*\.bandwidth\.com$/.test(url?.host) ||
      /.*\.voxbone\.com$/.test(url?.host) ||
      /^localhost:[0-9]*$/.test(url?.host);
    const isAuthenticated = await oktaAuth.isAuthenticated();

    if (!isAuthenticated) {
      return;
    }

    if (sessionStorage.getItem('idpr') === 'true') {
      const userinfo = await oktaAuth.token?.getUserInfo();
      logEvent(LogEvent.IdP, LogEventStatus.Successful, {
        username: userinfo?.preferred_username,
      });
      sessionStorage.removeItem('idpr');
    }
    if (authType === 'popup') {
      windowPostMessage({ isAuthenticated, originalUri });
      return;
    }

    if (
      url.href !== window.location.origin &&
      url.href !== window.location.href &&
      valid
    ) {
      window.location.replace(url.href);
    } else {
      window.location.replace(configValue.dashboard);
    }
  },
};

const matchesReactAppTarget = (env: string) =>
  env.toLowerCase() === process.env.REACT_APP_TARGET?.toLowerCase();

export const matchesEnvironment = (env: string) =>
  process.env.REACT_APP_TARGET
    ? matchesReactAppTarget(env)
    : window.location.hostname.startsWith(env);

const pickConfig = (): Config => {
  if (matchesEnvironment('passport') || matchesEnvironment('production')) {
    return {
      ...baseConfig,
      baseUrl: 'https://login.bandwidth.com',
      dashboard: 'https://dashboard.bandwidth.com',
      irisApi: 'https://dashboard.bandwidth.com/api/v2',
      oneIdManagementApi: 'https://management.id.bandwidth.com',
      oidc: {
        issuer: 'https://login.bandwidth.com/oauth2/aus6z3sybxny29ou82p7',
        clientId: '0oa9a0otabTk4PaXd2p7',
        ...baseOidcConfig,
      },
      datadog: {
        env: 'production',
        ...datadogProd,
      },
    };
  }

  if (matchesEnvironment('uat')) {
    return {
      ...baseConfig,
      baseUrl: 'https://uat.login.bandwidth.com',
      dashboard: 'https://test.dashboard.bandwidth.com',
      irisApi: 'https://test.dashboard.bandwidth.com/api/v2',
      oneIdManagementApi: 'https://uat.management.id.bandwidth.com',
      oidc: {
        issuer: 'https://uat.login.bandwidth.com/oauth2/ausr351h00uooRsos0h7',
        clientId: '0oavz8mdhfRCo3NBk0h7',
        ...baseOidcConfig,
      },
      datadog: {
        env: 'uat',
        ...datadogProd,
      },
    };
  }

  if (matchesEnvironment('review')) {
    return {
      ...baseConfig,
      baseUrl: 'https://pr.login.bandwidth.com',
      dashboard: 'https://dev.dashboard.bandwidth.com',
      irisApi: 'https://dev.dashboard.bandwidth.com/api/v2',
      oneIdManagementApi: 'https://pr.lab.management.id.bandwidth.com',
      oidc: {
        issuer: 'https://pr.login.bandwidth.com/oauth2/aus920iyubcf2O5Lg4x6',
        clientId: '0oa1ke99ojzi6i70P4x7',
        ...baseOidcConfig,
      },
      datadog: {
        env: 'review',
        ...datadogNonProd,
      },
    };
  }

  if (matchesEnvironment('qa') || matchesEnvironment('ci')) {
    return {
      ...baseConfig,
      baseUrl: 'https://ci.login.bandwidth.com',
      dashboard: 'https://qa.dashboard.bandwidth.com',
      irisApi: 'https://qa.dashboard.bandwidth.com/api/v2',
      oneIdManagementApi: 'https://ci.lab.management.id.bandwidth.com',
      oidc: {
        issuer: 'https://ci.login.bandwidth.com/oauth2/ausqxqyu1tW6zVd850h7',
        clientId: '0oavvd0gtxnnYhavp0h7',
        ...baseOidcConfig,
      },
      datadog: {
        env: 'qa',
        ...datadogNonProd,
      },
    };
  }

  if (matchesEnvironment('dev')) {
    return {
      ...baseConfig,
      baseUrl: 'https://pr.login.bandwidth.com',
      dashboard: 'https://dev.dashboard.bandwidth.com',
      irisApi: 'https://dev.dashboard.bandwidth.com/api/v2',
      oneIdManagementApi: 'https://pr.lab.management.id.bandwidth.com',
      oidc: {
        issuer: 'https://pr.login.bandwidth.com/oauth2/aus920iyubcf2O5Lg4x6',
        clientId: '0oa1ke99ojzi6i70P4x7',
        ...baseOidcConfig,
      },
      datadog: {
        env: 'dev',
        ...datadogNonProd,
      },
    };
  }

  // Default to eng environment
  return {
    ...baseConfig,
    baseUrl: 'https://stage.login.bandwidth.com',
    // Target stage unless dev explicitly uses REACT_APP_TARGET=local
    dashboard: matchesReactAppTarget('local')
      ? 'http://localhost:9001'
      : 'https://eng.dashboard.bandwidth.com',
    irisApi: 'https://eng.dashboard.bandwidth.com/api/v2',
    oneIdManagementApi: 'https://stage.lab.management.id.bandwidth.com',
    oidc: {
      issuer: 'https://stage.login.bandwidth.com/oauth2/ausqxr6vbsMh6tmN30h7',
      clientId: '0oavvcy4dc7RT1Pa70h7',
      ...baseOidcConfig,
    },
    datadog: {
      // Use local env if we are doing local development at all
      env: process.env.NODE_ENV === 'development' ? 'local' : 'stage',
      ...datadogNonProd,
    },
  };
};

const configValue = pickConfig();
// @ts-expect-error Missing type for window
window.CONFIG = configValue;

export default configValue;
