import { matchesEnvironment } from 'utils/config';

const allowReturnToUrl = (url: URL) => {
  const { hostname } = url;
  if (matchesEnvironment('passport') || matchesEnvironment('production')) {
    return (
      hostname === 'dashboard.bandwidth.com' ||
      hostname === 'numbers-history-app.dashboard.bandwidth.com' ||
      hostname === 'accounts.bandwidth.com'
    );
  }
  if (matchesEnvironment('uat')) {
    return (
      hostname === 'test.dashboard.bandwidth.com' ||
      hostname === 'numbers-history-app.test.dashboard.bandwidth.com' ||
      hostname === 'uat.accounts.bandwidth.com' ||
      hostname === 'uat.aibridge.bandwidth.com'
    );
  }
  return true;
};

export default allowReturnToUrl;
