import React from 'react';
import {
  AppBar,
  Toolbar,
  Logo,
  Typography,
  makeStyles,
  NavButton,
  Box,
  Theme,
} from '@bw/foundry';
import { useOktaAuth } from '@okta/okta-react';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(22),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    position: 'relative',
    top: -2,
  },
  logo: {
    // adjusted due to built-in logo space
    marginRight: theme.spacing(0.5),
  },
  box: {
    marginTop: theme.spacing(2),
  },
  logoDivider: {
    color: 'inherit',
    borderLeft: `1px solid ${theme.palette.secondary.contrastText}`,
    marginRight: theme.spacing(1),
    width: 1,
    height: 32,
  },
  navButton: {
    paddingTop: 20,
    paddingBottom: 20,
  },
}));

/** Basic navigation component that shows links in the nav bar. */
const Navigation: React.FC = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const classes = useStyles();
  if (authState?.isAuthenticated || oktaAuth?.isLoginRedirect()) {
    return null;
  }

  return (
    <AppBar color="primary" id="bwPassportNav">
      <Toolbar variant="dense">
        <Logo size={32} color="white" className={classes.logo} />
        <div className={classes.logoDivider} />
        <Typography variant="h1" color="inherit" className={classes.title}>
          Bandwidth
        </Typography>
        <Box ml="auto" display="flex" flexDirection="row" alignItems="center">
          <NavButton
            className={classes.navButton}
            target="_blank"
            href="https://bandwidth.com/privacy"
          >
            Privacy Notice
          </NavButton>

          <NavButton
            className={classes.navButton}
            target="_blank"
            href="https://support.bandwidth.com/home"
          >
            Support
          </NavButton>
          <NavButton
            className={classes.navButton}
            target="_blank"
            href="https://finance.bandwidth.com/"
          >
            Payments
          </NavButton>
          <NavButton
            className={classes.navButton}
            target="_blank"
            href="https://status.bandwidth.com/"
          >
            Service Status
          </NavButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
